@font-face {
  font-family: "nickelodeon2001headline";
  src: url("./nickelodeon2001headline.ttf");
}
@font-face {
  font-family: "HelveticaNeueCyr-Light";
  src: url("./HelveticaNeueCyr-Light.ttf");
}
@font-face {
  font-family: "HelveticaNeueCyr-Medium";
  src: url("./HelveticaNeueCyr-Medium.ttf");
}
